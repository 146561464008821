#gradient-canvas {
    position: absolute;
    z-index: -1;
    --gradient-color-1:#ef008f;
    --gradient-color-2:#6ec3f4;
    --gradient-color-3:#7038ff;
    --gradient-color-4:#e2e2e2;
}

#pattern-background {
    background-image: url("data:image/svg+xml,%3Csvg width='52' height='26' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%239C92AC' fill-opacity='0.1'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.price-card {
    /* background-color: #262626;
     */
     background: radial-gradient(circle at top right, rgba(38,38,38,1) 0%, rgba(28,40,42,1) 100%);
     /* background: white; */

}